import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import { extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    colors: {
      gcsblue: {
        50: "#dff1ff",
        100: "#b0d3ff",
        200: "#7fb6ff",
        300: "#4d99ff",
        400: "#1f7cfe",
        500: "#0962e5",
        600: "#004cb3",
        700: "#003781",
        800: "#002150",
        900: "#000b20",
      },
    },
    fonts: { heading: "Montserrat", body: "Montserrat" },
  });
  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
